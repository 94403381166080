<template>
  <span class="dictionary">
    <el-cascader
      :size="size"
      ref="elCascader"
      v-model="val"
      :clearable="clearable"
      :disabled="disabled"
      :placeholder="placeholder"
      :options="options"
      :filterable="filterable"
      :props="cascaderProps"
      @visible-change="visibleChange"
      @change="optionChange"
    >
    </el-cascader>
  </span>
</template>

<script>
import { initTreeData } from '@/util/common'
import { listSysDictData } from '@/api/dict'
export default {
  name: 'Dictionary',
  components: {},
  props: {
    filterable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      //是否显示清空按钮
      type: Boolean,
      default: true,
    },
    checkStrictly: {
      //是否可以选择分支节点
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    valKey: {
      type: String,
      default: 'dictVal',
    },
    expandTrigger: {
      type: String,
      default: 'hover',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'dictName',
    },
    code: {
      type: String,
    },
    value: {},
    size: {
      type: String,
      default: '',
    },
    Options: {
      type: Array,
      default: function () {
        return []
      },
    },
    disabledSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      that: this,
      val: '',
      options: this.Options || [],
      cascaderProps: {
        multiple: this.multiple,
        expandTrigger: this.expandTrigger,
        checkStrictly: this.checkStrictly,
        value: this.valKey,
        label: this.label,
        emitPath: false,
      },
    }
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  watch: {
    code: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        if (newVal) {
          this.init()
        }
      },
    },
    value: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        if (this.multiple) {
          if (newVal) {
            this.val = newVal.split(',')
          }
        } else {
          this.val = newVal
        }
        //this.val = newVal;
      },
    },
  },
  mounted() {},
  methods: {
    init() {
      this.$api.dict
        .listSysDictData(this.code, true)
        .then(res => {
          if (this.code === 'zhu_ying_ye_wu_cheng_ben') {
            const item = res.data.find(v => v.dictVal === 'lao_wu_fei')
            if (item) {
              item.disabled = true
            }
            const value = res.data.find(v => v.dictVal === 'wai_xie_fei_yong')
            if (value) {
              if (this.disabledSelect) {
                value.disabled = true
              } else {
                value.disabled = false
              }
            }
          }
          if (this.code === 'fu_wu_fei') {
            res.data.forEach(v => {
              if (v.dictVal === 'zhong_biao_fu_wu_fei' || v.dictVal === 'tou_biao_bao_ming_fei') {
                v.disabled = true
              }
            })
          }

          this.options = initTreeData(res.data)
          this.$emit('optionsChange', this.options)
        })
        .catch(err => {
          console.log(err)
        })
    },
    visibleChange(v) {
      if (v) {
        const container = document.querySelectorAll('.el-scrollbar__bar')
        container.forEach(item => {
          item.style.opacity = '1'
        })
      }
    },
    optionChange(v) {
      var value = ''
      if (v) {
        if (this.multiple) {
          value = v.join()
        } else {
          value = v
        }
      }
      this.$emit('dictionaryChange', this.$refs.elCascader.getCheckedNodes())
      this.$emit('change', value)
    },
  },
}
</script>

<stlye lang="scss" scoped>
.dictionary {
  padding-right: 15px;
  padding-bottom: 5px;
}
</stlye>
